<template>
  <div class="bg-white outmost-wrapper" :style="{ maxWidth }">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import ArmsRum from '@arms/rum-browser'
import '@vant/touch-emulator'
import VConsole from 'vconsole'
import { useSharedStore } from './stores/shared'
import { px2rem } from './utils/index'

const envConfig = useRuntimeConfig()
const router = useRouter()
const sharedStore = useSharedStore()
const isInPC = !/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) && window.screen.width > 430
const maxWidth = isInPC ? '430px' : 'none' // // 这里的px不要被转换成rem。只在PC下设置最大宽度。与nuxt config中的scale规则一致
let vConsole: VConsole
const APP_ENV = useRuntimeConfig().public.APP_ENV

if (APP_ENV === 'prod') {
  ArmsRum.init({
    pid: 'jkfap0wav0@47e4d4f6922f68e',
    endpoint: 'https://jkfap0wav0-default-cn.rum.aliyuncs.com'
  })
}

// const maxWidth = ref('100%')

// const viewportMeta = reactive({ name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, viewport-fit=cover,user-scalable=no' })

// useHead({
//   meta: [{
//     name: viewportMeta.name,
//     content: viewportMeta.content
//   }]
// })

watch(
  () => router.currentRoute.value,
  newVal => {
    sharedStore.isShowBottomNavigation = newVal.path === '/' || newVal.path === '/recharge' || newVal.path === '/pay'
    sharedStore.isShowBottomSafeArea = sharedStore.isShowBottomNavigation && isAllScreenDisplay() && (isInWechat() || isInQQ() || isInApp)
    sharedStore.BOTTOM_HEIGHT = 50
    sharedStore.LAYOUT_PADDING_BOTTOM = `${
      sharedStore.isShowBottomNavigation && sharedStore.isShowBottomSafeArea
        ? px2rem(sharedStore.BOTTOM_HEIGHT + sharedStore.BOTTOM_SAFE_AREA_HEIGHT)
        : px2rem(50)
    }rem`
  }
)

// const result = await useAsyncData('name', async () => {
//   const res = await axiosGetLuckyRules()
//   console.log(res)
//   return res?.data
// })

onMounted(() => {
  // if (!/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator?.userAgent) && window.screen.width > 430) {
  //   maxWidth.value = '375px'
  // }
  if (APP_ENV !== 'prod' && APP_ENV !== 'release') {
    // 测试和开发打开，生产不能打开
    vConsole = new VConsole()
  }

  sharedStore.isShowBottomNavigation =
    router.currentRoute.value.path === '/' || router.currentRoute.value.path === '/recharge' || router.currentRoute.value.path === '/pay'
  sharedStore.isShowBottomSafeArea = sharedStore.isShowBottomNavigation && isAllScreenDisplay() && (isInWechat() || isInQQ() || isInApp)
  sharedStore.BOTTOM_HEIGHT = 50
  sharedStore.LAYOUT_PADDING_BOTTOM = `${
    sharedStore.isShowBottomNavigation && sharedStore.isShowBottomSafeArea
      ? px2rem(sharedStore.BOTTOM_HEIGHT + sharedStore.BOTTOM_SAFE_AREA_HEIGHT)
      : px2rem(50)
  }rem`

  window.addEventListener('message', event => {
    if (event.origin === envConfig.public.CAT_URL) {
      console.log(event)
      if (typeof event.data !== 'string') {
        return
      }
      const info = JSON.parse(event?.data || '')
      if (info.from === 'recharge') {
        if (info.message === 'PAY_SUCCESS') {
          // showToast('兑换成功')
        }
        setTimeout(() => {
          if (info.origin.includes('lucky/detail')) {
            // 从饰品详情跳转到充值，充值成功后调用back
            if (isInApp) {
              native?.back()
            } else {
              const url = new URL(info.origin)
              router.replace(`/lucky/detail?id=${url.searchParams.get('id')}`)
            }
          } else {
            // 从底部tab进入充值，充值完成后直接跳转回首页
            router.replace('/')
          }
        }, 1000)
      }
    }
  })
})

onUnmounted(() => {
  if (vConsole) {
    vConsole.destroy()
  }
})
</script>

<style lang="scss">
/* 由于 vant 中的主题变量也是在 :root 下声明的，所以在有些情况下会由于优先级的问题无法成功覆盖。
通过 :root:root 可以显式地让你所写内容的优先级更高一些，从而确保主题变量的成功覆盖 */
:root:root {
  --van-button-mini-height: 24px;
  --van-button-mini-padding: 0 6px;
  --van-button-mini-font-size: 12px;
  --van-button-small-height: 28px;
  --van-button-small-padding: 0 7px;
  --van-button-small-font-size: 13px;
  --van-button-normal-font-size: 15px;
  --van-button-normal-padding: 0 8px;
  --van-button-default-height: 37px;
  --van-button-large-height: 40px;
  --van-button-default-color: #888;
  --van-button-default-border-color: #888;
  --van-button-default-color: #888;
  --van-button-default-border-color: #888;
  --van-primary-color: #fd802c;
  --van-action-sheet-header-font-size: 17px;
  --van-action-sheet-header-height: 1;
  --van-empty-description-margin-top: -15px; // 设计稿中默认文字在图片内部
}

:root {
  .van-action-sheet__header {
    color: #150000;
    font-weight: 500;
    padding: 20px 0 12px;
    .van-action-sheet__close {
      padding: 15px;
    }
  }
}

* {
  border: 0 solid theme('colors.grey.702');
  // border: 0;
  outline: none;
  padding: 0;
  margin: 0;
}

html {
  height: 100%;
  margin: 0 auto;
}

body {
  // height: 100%;
  background-color: rgb(243, 244, 245);
}

.outmost-wrapper {
  transform: scale3d(1, 1, 1);
  // prettier-ignore
  // transform: scale(1); // 使后代所有 position:fixed 定位的元素以该元素为基准
  overflow-y: hidden;
  overflow-x: hidden;
  margin: 0 auto;
  // height: 100%;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 0;
}
::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 2px;
  background-clip: content-box;
  border: 1px solid transparent;
}
</style>
