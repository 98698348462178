import { default as indexMaJBKRfeJMMeta } from "/app/pages/activity/douyu-24spring/code/index.vue?macro=true";
import { default as indextSqX1AVGfWMeta } from "/app/pages/activity/douyu-24spring/index.vue?macro=true";
import { default as indexyuDZOHIVUoMeta } from "/app/pages/activity/douyu-24spring/landing/index.vue?macro=true";
import { default as indexywHu4uee5LMeta } from "/app/pages/activity/douyu-24spring/received-reward/index.vue?macro=true";
import { default as indexf57XB8WQddMeta } from "/app/pages/activity/douyu-24spring/reward/index.vue?macro=true";
import { default as index6bAc0KjuS2Meta } from "/app/pages/activity/gift-box/index.vue?macro=true";
import { default as testI2ObhhPTUzMeta } from "/app/pages/activity/gift-box/test.vue?macro=true";
import { default as indexMjNzMI6araMeta } from "/app/pages/activity/sell-rebate/index.vue?macro=true";
import { default as AwardItemHh2naDbwGsMeta } from "/app/pages/activity/student-verify/AwardItem.vue?macro=true";
import { default as SuccessDialogG9HFgcqEK1Meta } from "/app/pages/activity/student-verify/SuccessDialog.vue?macro=true";
import { default as indexoLRHqGKiQ2Meta } from "/app/pages/activity/student-verify/index.vue?macro=true";
import { default as indexkiar0VLM1gMeta } from "/app/pages/activity/wheel/index.vue?macro=true";
import { default as indexuHyZwPZZ0iMeta } from "/app/pages/activity/wheel/reward/index.vue?macro=true";
import { default as testxVIoKkUh0PMeta } from "/app/pages/activity/wheel/reward/test.vue?macro=true";
import { default as indexd1R3a0kC8CMeta } from "/app/pages/activity/wheel/rule/index.vue?macro=true";
import { default as indexOc6XpaBGcwMeta } from "/app/pages/banner-proxy/index.vue?macro=true";
import { default as detail1bX9w3eus8Meta } from "/app/pages/buy-one-get-one/detail.vue?macro=true";
import { default as indexhUJ8ixKFGUMeta } from "/app/pages/buy-one-get-one/fairness/index.vue?macro=true";
import { default as indexBSMPMlV1DJMeta } from "/app/pages/buy-one-get-one/fairness/info/index.vue?macro=true";
import { default as indexWro5daIHfjMeta } from "/app/pages/buy-one-get-one/history/detail/index.vue?macro=true";
import { default as indexJBM8oEfklJMeta } from "/app/pages/buy-one-get-one/history/index.vue?macro=true";
import { default as indexovlpa0ANrYMeta } from "/app/pages/buy-one-get-one/index.vue?macro=true";
import { default as indexub017fTwKtMeta } from "/app/pages/buy-one-get-one/payment/index.vue?macro=true";
import { default as errorcxo3GvC8JMMeta } from "/app/pages/error.vue?macro=true";
import { default as indexEdp8q4fQvvMeta } from "/app/pages/help/overdue-interest/index.vue?macro=true";
import { default as index4aGTGBC5tJMeta } from "/app/pages/home/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexZDdJdK9PKnMeta } from "/app/pages/knapsack/index.vue?macro=true";
import { default as indexF5qTjCtQy6Meta } from "/app/pages/knapsack/turnover/index.vue?macro=true";
import { default as upgrade_45detailoVhDX37WgSMeta } from "/app/pages/knapsack/turnover/upgrade-detail.vue?macro=true";
import { default as indexgK5VicK7y2Meta } from "/app/pages/login/index.vue?macro=true";
import { default as index3jNPahJ3rYMeta } from "/app/pages/login/resetPwd/index.vue?macro=true";
import { default as animation_45progressBcUUNJTCjGMeta } from "/app/pages/lucky/detail/component/animation-progress.vue?macro=true";
import { default as my_45flexdC2jsrOMSSMeta } from "/app/pages/lucky/detail/component/my-flex.vue?macro=true";
import { default as my_45good_45itemTLCEN4lUZTMeta } from "/app/pages/lucky/detail/component/my-good-item.vue?macro=true";
import { default as indexU2qtF5Y8SAMeta } from "/app/pages/lucky/detail/index.vue?macro=true";
import { default as followItemCHSK7tJeOAMeta } from "/app/pages/lucky/follow-list/component/followItem.vue?macro=true";
import { default as indexjtGb9gUsheMeta } from "/app/pages/lucky/follow-list/index.vue?macro=true";
import { default as indexjxyohEdXN6Meta } from "/app/pages/lucky/index.vue?macro=true";
import { default as indexihwLCSbnQFMeta } from "/app/pages/lucky/luck-moment/index.vue?macro=true";
import { default as indexnkqqIgTNHeMeta } from "/app/pages/lucky/notification/index.vue?macro=true";
import { default as prize_45dialogvoRk0Q9tHAMeta } from "/app/pages/lucky/red-packet/component/prize-dialog.vue?macro=true";
import { default as index3rHY0ozIARMeta } from "/app/pages/lucky/red-packet/index.vue?macro=true";
import { default as indexeaIZ6lLdcLMeta } from "/app/pages/pay/index.vue?macro=true";
import { default as indexEObye89EGtMeta } from "/app/pages/purchase/index.vue?macro=true";
import { default as indexnEZldjJnAlMeta } from "/app/pages/recharge/index.vue?macro=true";
import { default as indexTce1s0abJbMeta } from "/app/pages/refund/index.vue?macro=true";
import { default as submittedBVyAHC7qDDMeta } from "/app/pages/refund/submitted.vue?macro=true";
import { default as success2Kvk6HGIVDMeta } from "/app/pages/refund/success.vue?macro=true";
import { default as indexP0q8KYV1WMMeta } from "/app/pages/search/index.vue?macro=true";
import { default as _91id_93L4boBlPZEeMeta } from "/app/pages/terms/[id].vue?macro=true";
import { default as indexQBWpBgVI9bMeta } from "/app/pages/thirdauth/index.vue?macro=true";
import { default as index34A2der6DJMeta } from "/app/pages/upgrade/index.vue?macro=true";
import { default as wechat_45testf4wHDRxW5TMeta } from "/app/pages/wechat-test.vue?macro=true";
export default [
  {
    name: indexMaJBKRfeJMMeta?.name ?? "activity-douyu-24spring-code",
    path: indexMaJBKRfeJMMeta?.path ?? "/activity/douyu-24spring/code",
    meta: indexMaJBKRfeJMMeta || {},
    alias: indexMaJBKRfeJMMeta?.alias || [],
    redirect: indexMaJBKRfeJMMeta?.redirect || undefined,
    component: () => import("/app/pages/activity/douyu-24spring/code/index.vue").then(m => m.default || m)
  },
  {
    name: indextSqX1AVGfWMeta?.name ?? "activity-douyu-24spring",
    path: indextSqX1AVGfWMeta?.path ?? "/activity/douyu-24spring",
    meta: indextSqX1AVGfWMeta || {},
    alias: indextSqX1AVGfWMeta?.alias || [],
    redirect: indextSqX1AVGfWMeta?.redirect || undefined,
    component: () => import("/app/pages/activity/douyu-24spring/index.vue").then(m => m.default || m)
  },
  {
    name: indexyuDZOHIVUoMeta?.name ?? "activity-douyu-24spring-landing",
    path: indexyuDZOHIVUoMeta?.path ?? "/activity/douyu-24spring/landing",
    meta: indexyuDZOHIVUoMeta || {},
    alias: indexyuDZOHIVUoMeta?.alias || [],
    redirect: indexyuDZOHIVUoMeta?.redirect || undefined,
    component: () => import("/app/pages/activity/douyu-24spring/landing/index.vue").then(m => m.default || m)
  },
  {
    name: indexywHu4uee5LMeta?.name ?? "activity-douyu-24spring-received-reward",
    path: indexywHu4uee5LMeta?.path ?? "/activity/douyu-24spring/received-reward",
    meta: indexywHu4uee5LMeta || {},
    alias: indexywHu4uee5LMeta?.alias || [],
    redirect: indexywHu4uee5LMeta?.redirect || undefined,
    component: () => import("/app/pages/activity/douyu-24spring/received-reward/index.vue").then(m => m.default || m)
  },
  {
    name: indexf57XB8WQddMeta?.name ?? "activity-douyu-24spring-reward",
    path: indexf57XB8WQddMeta?.path ?? "/activity/douyu-24spring/reward",
    meta: indexf57XB8WQddMeta || {},
    alias: indexf57XB8WQddMeta?.alias || [],
    redirect: indexf57XB8WQddMeta?.redirect || undefined,
    component: () => import("/app/pages/activity/douyu-24spring/reward/index.vue").then(m => m.default || m)
  },
  {
    name: index6bAc0KjuS2Meta?.name ?? "activity-gift-box",
    path: index6bAc0KjuS2Meta?.path ?? "/activity/gift-box",
    meta: index6bAc0KjuS2Meta || {},
    alias: index6bAc0KjuS2Meta?.alias || [],
    redirect: index6bAc0KjuS2Meta?.redirect || undefined,
    component: () => import("/app/pages/activity/gift-box/index.vue").then(m => m.default || m)
  },
  {
    name: testI2ObhhPTUzMeta?.name ?? "activity-gift-box-test",
    path: testI2ObhhPTUzMeta?.path ?? "/activity/gift-box/test",
    meta: testI2ObhhPTUzMeta || {},
    alias: testI2ObhhPTUzMeta?.alias || [],
    redirect: testI2ObhhPTUzMeta?.redirect || undefined,
    component: () => import("/app/pages/activity/gift-box/test.vue").then(m => m.default || m)
  },
  {
    name: indexMjNzMI6araMeta?.name ?? "activity-sell-rebate",
    path: indexMjNzMI6araMeta?.path ?? "/activity/sell-rebate",
    meta: indexMjNzMI6araMeta || {},
    alias: indexMjNzMI6araMeta?.alias || [],
    redirect: indexMjNzMI6araMeta?.redirect || undefined,
    component: () => import("/app/pages/activity/sell-rebate/index.vue").then(m => m.default || m)
  },
  {
    name: AwardItemHh2naDbwGsMeta?.name ?? "activity-student-verify-AwardItem",
    path: AwardItemHh2naDbwGsMeta?.path ?? "/activity/student-verify/AwardItem",
    meta: AwardItemHh2naDbwGsMeta || {},
    alias: AwardItemHh2naDbwGsMeta?.alias || [],
    redirect: AwardItemHh2naDbwGsMeta?.redirect || undefined,
    component: () => import("/app/pages/activity/student-verify/AwardItem.vue").then(m => m.default || m)
  },
  {
    name: SuccessDialogG9HFgcqEK1Meta?.name ?? "activity-student-verify-SuccessDialog",
    path: SuccessDialogG9HFgcqEK1Meta?.path ?? "/activity/student-verify/SuccessDialog",
    meta: SuccessDialogG9HFgcqEK1Meta || {},
    alias: SuccessDialogG9HFgcqEK1Meta?.alias || [],
    redirect: SuccessDialogG9HFgcqEK1Meta?.redirect || undefined,
    component: () => import("/app/pages/activity/student-verify/SuccessDialog.vue").then(m => m.default || m)
  },
  {
    name: indexoLRHqGKiQ2Meta?.name ?? "activity-student-verify",
    path: indexoLRHqGKiQ2Meta?.path ?? "/activity/student-verify",
    meta: indexoLRHqGKiQ2Meta || {},
    alias: indexoLRHqGKiQ2Meta?.alias || [],
    redirect: indexoLRHqGKiQ2Meta?.redirect || undefined,
    component: () => import("/app/pages/activity/student-verify/index.vue").then(m => m.default || m)
  },
  {
    name: indexkiar0VLM1gMeta?.name ?? "activity-wheel",
    path: indexkiar0VLM1gMeta?.path ?? "/activity/wheel",
    meta: indexkiar0VLM1gMeta || {},
    alias: indexkiar0VLM1gMeta?.alias || [],
    redirect: indexkiar0VLM1gMeta?.redirect || undefined,
    component: () => import("/app/pages/activity/wheel/index.vue").then(m => m.default || m)
  },
  {
    name: indexuHyZwPZZ0iMeta?.name ?? "activity-wheel-reward",
    path: indexuHyZwPZZ0iMeta?.path ?? "/activity/wheel/reward",
    meta: indexuHyZwPZZ0iMeta || {},
    alias: indexuHyZwPZZ0iMeta?.alias || [],
    redirect: indexuHyZwPZZ0iMeta?.redirect || undefined,
    component: () => import("/app/pages/activity/wheel/reward/index.vue").then(m => m.default || m)
  },
  {
    name: testxVIoKkUh0PMeta?.name ?? "activity-wheel-reward-test",
    path: testxVIoKkUh0PMeta?.path ?? "/activity/wheel/reward/test",
    meta: testxVIoKkUh0PMeta || {},
    alias: testxVIoKkUh0PMeta?.alias || [],
    redirect: testxVIoKkUh0PMeta?.redirect || undefined,
    component: () => import("/app/pages/activity/wheel/reward/test.vue").then(m => m.default || m)
  },
  {
    name: indexd1R3a0kC8CMeta?.name ?? "activity-wheel-rule",
    path: indexd1R3a0kC8CMeta?.path ?? "/activity/wheel/rule",
    meta: indexd1R3a0kC8CMeta || {},
    alias: indexd1R3a0kC8CMeta?.alias || [],
    redirect: indexd1R3a0kC8CMeta?.redirect || undefined,
    component: () => import("/app/pages/activity/wheel/rule/index.vue").then(m => m.default || m)
  },
  {
    name: indexOc6XpaBGcwMeta?.name ?? "banner-proxy",
    path: indexOc6XpaBGcwMeta?.path ?? "/banner-proxy",
    meta: indexOc6XpaBGcwMeta || {},
    alias: indexOc6XpaBGcwMeta?.alias || [],
    redirect: indexOc6XpaBGcwMeta?.redirect || undefined,
    component: () => import("/app/pages/banner-proxy/index.vue").then(m => m.default || m)
  },
  {
    name: detail1bX9w3eus8Meta?.name ?? "buy-one-get-one-detail",
    path: detail1bX9w3eus8Meta?.path ?? "/buy-one-get-one/detail",
    meta: detail1bX9w3eus8Meta || {},
    alias: detail1bX9w3eus8Meta?.alias || [],
    redirect: detail1bX9w3eus8Meta?.redirect || undefined,
    component: () => import("/app/pages/buy-one-get-one/detail.vue").then(m => m.default || m)
  },
  {
    name: indexhUJ8ixKFGUMeta?.name ?? "buy-one-get-one-fairness",
    path: indexhUJ8ixKFGUMeta?.path ?? "/buy-one-get-one/fairness",
    meta: indexhUJ8ixKFGUMeta || {},
    alias: indexhUJ8ixKFGUMeta?.alias || [],
    redirect: indexhUJ8ixKFGUMeta?.redirect || undefined,
    component: () => import("/app/pages/buy-one-get-one/fairness/index.vue").then(m => m.default || m)
  },
  {
    name: indexBSMPMlV1DJMeta?.name ?? "buy-one-get-one-fairness-info",
    path: indexBSMPMlV1DJMeta?.path ?? "/buy-one-get-one/fairness/info",
    meta: indexBSMPMlV1DJMeta || {},
    alias: indexBSMPMlV1DJMeta?.alias || [],
    redirect: indexBSMPMlV1DJMeta?.redirect || undefined,
    component: () => import("/app/pages/buy-one-get-one/fairness/info/index.vue").then(m => m.default || m)
  },
  {
    name: indexWro5daIHfjMeta?.name ?? "buy-one-get-one-history-detail",
    path: indexWro5daIHfjMeta?.path ?? "/buy-one-get-one/history/detail",
    meta: indexWro5daIHfjMeta || {},
    alias: indexWro5daIHfjMeta?.alias || [],
    redirect: indexWro5daIHfjMeta?.redirect || undefined,
    component: () => import("/app/pages/buy-one-get-one/history/detail/index.vue").then(m => m.default || m)
  },
  {
    name: indexJBM8oEfklJMeta?.name ?? "buy-one-get-one-history",
    path: indexJBM8oEfklJMeta?.path ?? "/buy-one-get-one/history",
    meta: indexJBM8oEfklJMeta || {},
    alias: indexJBM8oEfklJMeta?.alias || [],
    redirect: indexJBM8oEfklJMeta?.redirect || undefined,
    component: () => import("/app/pages/buy-one-get-one/history/index.vue").then(m => m.default || m)
  },
  {
    name: indexovlpa0ANrYMeta?.name ?? "buy-one-get-one",
    path: indexovlpa0ANrYMeta?.path ?? "/buy-one-get-one",
    meta: indexovlpa0ANrYMeta || {},
    alias: indexovlpa0ANrYMeta?.alias || [],
    redirect: indexovlpa0ANrYMeta?.redirect || undefined,
    component: () => import("/app/pages/buy-one-get-one/index.vue").then(m => m.default || m)
  },
  {
    name: indexub017fTwKtMeta?.name ?? "buy-one-get-one-payment",
    path: indexub017fTwKtMeta?.path ?? "/buy-one-get-one/payment",
    meta: indexub017fTwKtMeta || {},
    alias: indexub017fTwKtMeta?.alias || [],
    redirect: indexub017fTwKtMeta?.redirect || undefined,
    component: () => import("/app/pages/buy-one-get-one/payment/index.vue").then(m => m.default || m)
  },
  {
    name: errorcxo3GvC8JMMeta?.name ?? "error",
    path: errorcxo3GvC8JMMeta?.path ?? "/error",
    meta: errorcxo3GvC8JMMeta || {},
    alias: errorcxo3GvC8JMMeta?.alias || [],
    redirect: errorcxo3GvC8JMMeta?.redirect || undefined,
    component: () => import("/app/pages/error.vue").then(m => m.default || m)
  },
  {
    name: indexEdp8q4fQvvMeta?.name ?? "help-overdue-interest",
    path: indexEdp8q4fQvvMeta?.path ?? "/help/overdue-interest",
    meta: indexEdp8q4fQvvMeta || {},
    alias: indexEdp8q4fQvvMeta?.alias || [],
    redirect: indexEdp8q4fQvvMeta?.redirect || undefined,
    component: () => import("/app/pages/help/overdue-interest/index.vue").then(m => m.default || m)
  },
  {
    name: index4aGTGBC5tJMeta?.name ?? "home",
    path: index4aGTGBC5tJMeta?.path ?? "/home",
    meta: index4aGTGBC5tJMeta || {},
    alias: index4aGTGBC5tJMeta?.alias || [],
    redirect: index4aGTGBC5tJMeta?.redirect || undefined,
    component: () => import("/app/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexZDdJdK9PKnMeta?.name ?? "knapsack",
    path: indexZDdJdK9PKnMeta?.path ?? "/knapsack",
    meta: indexZDdJdK9PKnMeta || {},
    alias: indexZDdJdK9PKnMeta?.alias || [],
    redirect: indexZDdJdK9PKnMeta?.redirect || undefined,
    component: () => import("/app/pages/knapsack/index.vue").then(m => m.default || m)
  },
  {
    name: indexF5qTjCtQy6Meta?.name ?? "knapsack-turnover",
    path: indexF5qTjCtQy6Meta?.path ?? "/knapsack/turnover",
    meta: indexF5qTjCtQy6Meta || {},
    alias: indexF5qTjCtQy6Meta?.alias || [],
    redirect: indexF5qTjCtQy6Meta?.redirect || undefined,
    component: () => import("/app/pages/knapsack/turnover/index.vue").then(m => m.default || m)
  },
  {
    name: upgrade_45detailoVhDX37WgSMeta?.name ?? "knapsack-turnover-upgrade-detail",
    path: upgrade_45detailoVhDX37WgSMeta?.path ?? "/knapsack/turnover/upgrade-detail",
    meta: upgrade_45detailoVhDX37WgSMeta || {},
    alias: upgrade_45detailoVhDX37WgSMeta?.alias || [],
    redirect: upgrade_45detailoVhDX37WgSMeta?.redirect || undefined,
    component: () => import("/app/pages/knapsack/turnover/upgrade-detail.vue").then(m => m.default || m)
  },
  {
    name: indexgK5VicK7y2Meta?.name ?? "login",
    path: indexgK5VicK7y2Meta?.path ?? "/login",
    meta: indexgK5VicK7y2Meta || {},
    alias: indexgK5VicK7y2Meta?.alias || [],
    redirect: indexgK5VicK7y2Meta?.redirect || undefined,
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index3jNPahJ3rYMeta?.name ?? "login-resetPwd",
    path: index3jNPahJ3rYMeta?.path ?? "/login/resetPwd",
    meta: index3jNPahJ3rYMeta || {},
    alias: index3jNPahJ3rYMeta?.alias || [],
    redirect: index3jNPahJ3rYMeta?.redirect || undefined,
    component: () => import("/app/pages/login/resetPwd/index.vue").then(m => m.default || m)
  },
  {
    name: animation_45progressBcUUNJTCjGMeta?.name ?? "lucky-detail-component-animation-progress",
    path: animation_45progressBcUUNJTCjGMeta?.path ?? "/lucky/detail/component/animation-progress",
    meta: animation_45progressBcUUNJTCjGMeta || {},
    alias: animation_45progressBcUUNJTCjGMeta?.alias || [],
    redirect: animation_45progressBcUUNJTCjGMeta?.redirect || undefined,
    component: () => import("/app/pages/lucky/detail/component/animation-progress.vue").then(m => m.default || m)
  },
  {
    name: my_45flexdC2jsrOMSSMeta?.name ?? "lucky-detail-component-my-flex",
    path: my_45flexdC2jsrOMSSMeta?.path ?? "/lucky/detail/component/my-flex",
    meta: my_45flexdC2jsrOMSSMeta || {},
    alias: my_45flexdC2jsrOMSSMeta?.alias || [],
    redirect: my_45flexdC2jsrOMSSMeta?.redirect || undefined,
    component: () => import("/app/pages/lucky/detail/component/my-flex.vue").then(m => m.default || m)
  },
  {
    name: my_45good_45itemTLCEN4lUZTMeta?.name ?? "lucky-detail-component-my-good-item",
    path: my_45good_45itemTLCEN4lUZTMeta?.path ?? "/lucky/detail/component/my-good-item",
    meta: my_45good_45itemTLCEN4lUZTMeta || {},
    alias: my_45good_45itemTLCEN4lUZTMeta?.alias || [],
    redirect: my_45good_45itemTLCEN4lUZTMeta?.redirect || undefined,
    component: () => import("/app/pages/lucky/detail/component/my-good-item.vue").then(m => m.default || m)
  },
  {
    name: indexU2qtF5Y8SAMeta?.name ?? "lucky-detail",
    path: indexU2qtF5Y8SAMeta?.path ?? "/lucky/detail",
    meta: indexU2qtF5Y8SAMeta || {},
    alias: indexU2qtF5Y8SAMeta?.alias || [],
    redirect: indexU2qtF5Y8SAMeta?.redirect || undefined,
    component: () => import("/app/pages/lucky/detail/index.vue").then(m => m.default || m)
  },
  {
    name: followItemCHSK7tJeOAMeta?.name ?? "lucky-follow-list-component-followItem",
    path: followItemCHSK7tJeOAMeta?.path ?? "/lucky/follow-list/component/followItem",
    meta: followItemCHSK7tJeOAMeta || {},
    alias: followItemCHSK7tJeOAMeta?.alias || [],
    redirect: followItemCHSK7tJeOAMeta?.redirect || undefined,
    component: () => import("/app/pages/lucky/follow-list/component/followItem.vue").then(m => m.default || m)
  },
  {
    name: indexjtGb9gUsheMeta?.name ?? "lucky-follow-list",
    path: indexjtGb9gUsheMeta?.path ?? "/lucky/follow-list",
    meta: indexjtGb9gUsheMeta || {},
    alias: indexjtGb9gUsheMeta?.alias || [],
    redirect: indexjtGb9gUsheMeta?.redirect || undefined,
    component: () => import("/app/pages/lucky/follow-list/index.vue").then(m => m.default || m)
  },
  {
    name: indexjxyohEdXN6Meta?.name ?? "lucky",
    path: indexjxyohEdXN6Meta?.path ?? "/lucky",
    meta: indexjxyohEdXN6Meta || {},
    alias: indexjxyohEdXN6Meta?.alias || [],
    redirect: indexjxyohEdXN6Meta?.redirect || undefined,
    component: () => import("/app/pages/lucky/index.vue").then(m => m.default || m)
  },
  {
    name: indexihwLCSbnQFMeta?.name ?? "lucky-luck-moment",
    path: indexihwLCSbnQFMeta?.path ?? "/lucky/luck-moment",
    meta: indexihwLCSbnQFMeta || {},
    alias: indexihwLCSbnQFMeta?.alias || [],
    redirect: indexihwLCSbnQFMeta?.redirect || undefined,
    component: () => import("/app/pages/lucky/luck-moment/index.vue").then(m => m.default || m)
  },
  {
    name: indexnkqqIgTNHeMeta?.name ?? "lucky-notification",
    path: indexnkqqIgTNHeMeta?.path ?? "/lucky/notification",
    meta: indexnkqqIgTNHeMeta || {},
    alias: indexnkqqIgTNHeMeta?.alias || [],
    redirect: indexnkqqIgTNHeMeta?.redirect || undefined,
    component: () => import("/app/pages/lucky/notification/index.vue").then(m => m.default || m)
  },
  {
    name: prize_45dialogvoRk0Q9tHAMeta?.name ?? "lucky-red-packet-component-prize-dialog",
    path: prize_45dialogvoRk0Q9tHAMeta?.path ?? "/lucky/red-packet/component/prize-dialog",
    meta: prize_45dialogvoRk0Q9tHAMeta || {},
    alias: prize_45dialogvoRk0Q9tHAMeta?.alias || [],
    redirect: prize_45dialogvoRk0Q9tHAMeta?.redirect || undefined,
    component: () => import("/app/pages/lucky/red-packet/component/prize-dialog.vue").then(m => m.default || m)
  },
  {
    name: index3rHY0ozIARMeta?.name ?? "lucky-red-packet",
    path: index3rHY0ozIARMeta?.path ?? "/lucky/red-packet",
    meta: index3rHY0ozIARMeta || {},
    alias: index3rHY0ozIARMeta?.alias || [],
    redirect: index3rHY0ozIARMeta?.redirect || undefined,
    component: () => import("/app/pages/lucky/red-packet/index.vue").then(m => m.default || m)
  },
  {
    name: indexeaIZ6lLdcLMeta?.name ?? "pay",
    path: indexeaIZ6lLdcLMeta?.path ?? "/pay",
    meta: indexeaIZ6lLdcLMeta || {},
    alias: indexeaIZ6lLdcLMeta?.alias || [],
    redirect: indexeaIZ6lLdcLMeta?.redirect || undefined,
    component: () => import("/app/pages/pay/index.vue").then(m => m.default || m)
  },
  {
    name: indexEObye89EGtMeta?.name ?? "purchase",
    path: indexEObye89EGtMeta?.path ?? "/purchase",
    meta: indexEObye89EGtMeta || {},
    alias: indexEObye89EGtMeta?.alias || [],
    redirect: indexEObye89EGtMeta?.redirect || undefined,
    component: () => import("/app/pages/purchase/index.vue").then(m => m.default || m)
  },
  {
    name: indexnEZldjJnAlMeta?.name ?? "recharge",
    path: indexnEZldjJnAlMeta?.path ?? "/recharge",
    meta: indexnEZldjJnAlMeta || {},
    alias: indexnEZldjJnAlMeta?.alias || [],
    redirect: indexnEZldjJnAlMeta?.redirect || undefined,
    component: () => import("/app/pages/recharge/index.vue").then(m => m.default || m)
  },
  {
    name: indexTce1s0abJbMeta?.name ?? "refund",
    path: indexTce1s0abJbMeta?.path ?? "/refund",
    meta: indexTce1s0abJbMeta || {},
    alias: indexTce1s0abJbMeta?.alias || [],
    redirect: indexTce1s0abJbMeta?.redirect || undefined,
    component: () => import("/app/pages/refund/index.vue").then(m => m.default || m)
  },
  {
    name: submittedBVyAHC7qDDMeta?.name ?? "refund-submitted",
    path: submittedBVyAHC7qDDMeta?.path ?? "/refund/submitted",
    meta: submittedBVyAHC7qDDMeta || {},
    alias: submittedBVyAHC7qDDMeta?.alias || [],
    redirect: submittedBVyAHC7qDDMeta?.redirect || undefined,
    component: () => import("/app/pages/refund/submitted.vue").then(m => m.default || m)
  },
  {
    name: success2Kvk6HGIVDMeta?.name ?? "refund-success",
    path: success2Kvk6HGIVDMeta?.path ?? "/refund/success",
    meta: success2Kvk6HGIVDMeta || {},
    alias: success2Kvk6HGIVDMeta?.alias || [],
    redirect: success2Kvk6HGIVDMeta?.redirect || undefined,
    component: () => import("/app/pages/refund/success.vue").then(m => m.default || m)
  },
  {
    name: indexP0q8KYV1WMMeta?.name ?? "search",
    path: indexP0q8KYV1WMMeta?.path ?? "/search",
    meta: indexP0q8KYV1WMMeta || {},
    alias: indexP0q8KYV1WMMeta?.alias || [],
    redirect: indexP0q8KYV1WMMeta?.redirect || undefined,
    component: () => import("/app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93L4boBlPZEeMeta?.name ?? "terms-id",
    path: _91id_93L4boBlPZEeMeta?.path ?? "/terms/:id()",
    meta: _91id_93L4boBlPZEeMeta || {},
    alias: _91id_93L4boBlPZEeMeta?.alias || [],
    redirect: _91id_93L4boBlPZEeMeta?.redirect || undefined,
    component: () => import("/app/pages/terms/[id].vue").then(m => m.default || m)
  },
  {
    name: indexQBWpBgVI9bMeta?.name ?? "thirdauth",
    path: indexQBWpBgVI9bMeta?.path ?? "/thirdauth",
    meta: indexQBWpBgVI9bMeta || {},
    alias: indexQBWpBgVI9bMeta?.alias || [],
    redirect: indexQBWpBgVI9bMeta?.redirect || undefined,
    component: () => import("/app/pages/thirdauth/index.vue").then(m => m.default || m)
  },
  {
    name: index34A2der6DJMeta?.name ?? "upgrade",
    path: index34A2der6DJMeta?.path ?? "/upgrade",
    meta: index34A2der6DJMeta || {},
    alias: index34A2der6DJMeta?.alias || [],
    redirect: index34A2der6DJMeta?.redirect || undefined,
    component: () => import("/app/pages/upgrade/index.vue").then(m => m.default || m)
  },
  {
    name: wechat_45testf4wHDRxW5TMeta?.name ?? "wechat-test",
    path: wechat_45testf4wHDRxW5TMeta?.path ?? "/wechat-test",
    meta: wechat_45testf4wHDRxW5TMeta || {},
    alias: wechat_45testf4wHDRxW5TMeta?.alias || [],
    redirect: wechat_45testf4wHDRxW5TMeta?.redirect || undefined,
    component: () => import("/app/pages/wechat-test.vue").then(m => m.default || m)
  }
]