import { defineStore } from 'pinia'
import Cookies from 'js-cookie'
import { nanoid } from 'nanoid'
import piniaPersistConfig from '@/utils/piniaPersistConfig'

type TTheme = 'light' | 'dark'

interface ISharedStore {
  userInfo: any
  theme: any
  themePrimary: any
  audioSwitch: boolean
  isMessageDotShow: boolean // 是否展示消息列表中的未读标识
  isKnapsackDotShow: boolean // 是否展示背包消息中的未读标识
  C5ToolBarHeight: number

  isShowBottomNavigation: boolean // 是否展示底部导航
  isShowBottomSafeArea: boolean // 是否展示底部安全区

  LAYOUT_PADDING_BOTTOM: string // default layout的padding-bottom
  HEADER_HEIGHT: number // vant-nav-bar在当前机型的实际高度
  BOTTOM_HEIGHT: number // vant-tab-bar在当前机型的实际高度
  BOTTOM_SAFE_AREA_HEIGHT: number
  homeFilter: any
  fallbackDeviceId: string
}

export const useSharedStore = defineStore({
  id: 'SharedStore',
  state(): ISharedStore {
    return {
      // 用户信息
      userInfo: null,

      theme: Cookies.get('theme-mode') || 'light',

      // 主题颜色
      themePrimary: Cookies.get('theme-mode') || 'light',

      // 音效开关
      audioSwitch: true,
      isMessageDotShow: false, // 是否展示消息列表中的未读标识
      isKnapsackDotShow: false, // 是否展示背包消息中的未读标识
      isShowBottomNavigation: false, // 是否展示底部导航
      isShowBottomSafeArea: false, // 是否展示底部安全区
      C5ToolBarHeight: 0,

      HEADER_HEIGHT: 46, // vant-nav-bar在当前机型的实际高度
      BOTTOM_HEIGHT: 50, // vant-tab-bar在当前机型的实际高度
      BOTTOM_SAFE_AREA_HEIGHT: 25, // 自定义的底部安全区高度
      LAYOUT_PADDING_BOTTOM: '0px',
      homeFilter: null,
      fallbackDeviceId: nanoid()
    }
  },
  getters: {
    // 获取用户信息
    getUserInfo: state => state.userInfo,

    // 获取主题颜色
    getTheme: state => state.theme,

    // 是否是亮色主题
    isLight: state => state.theme === 'light'
  },
  actions: {
    setUserInfo(userInfo: any) {
      this.userInfo = userInfo
    },

    setTheme(theme: TTheme) {
      this.theme = theme
    },

    setAudioEnable(audioSwitch: boolean) {
      this.audioSwitch = audioSwitch
    },
    setIsMessageDotShow(value: boolean) {
      this.isMessageDotShow = value
    },
    setIsKnapsackDotShow(value: boolean) {
      this.isKnapsackDotShow = value
    },
    setC5ToolBarHeight(value: number) {
      this.C5ToolBarHeight = value
    },

    setIsShowBottomNavigation(value: boolean) {
      this.isShowBottomNavigation = value
    },
    setIsShowBottomSafeArea(value: boolean) {
      this.isShowBottomSafeArea = value
    },

    setHeaderHeight(value: number) {
      this.HEADER_HEIGHT = value
    },
    setBottomHeight(value: number) {
      this.BOTTOM_HEIGHT = value
    },
    setHomeFilter(value: any) {
      this.homeFilter = value
    }
  },
  persist: piniaPersistConfig('SharedStore')
})
